import withDashboardLayout from '../components/layout';
import { Link } from "react-router-dom";
import { Component, Fragment } from 'react';
import { withUtils } from '../utils';

class Widgets extends Component {
    constructor(props) {
        super(props)

        const surveyUuid = props.match.params.surveyUuid;

        this.state = {
            surveyUuid: surveyUuid || '...',
        };
    }

    render() {
        return (
            <Fragment>
                <h1>Widgets</h1>
                <p>
                    The Onva platform is provided as a way to seemlessly integrate continuous customer feedback with existing products, and we encourage you to visit the <Link href="https://api.onva.io/redoc/" target="_blank" rel="noreferrer">API documentation</Link> to understand some of the possibilities available with the platform. We've provided some sample code which can be used to quickly integrate a survey with an existing website, either through an embedded survey which is added to an existing site with the aim of appearing as natural as possible, or as a popup dialog which will appear above the existing page and not intefere with the flow of the page.
                </p>
                <p>
                    Both of these samples are built on top of a vanilla Javascript library that code be found at <Link href="https://widgets.onva.io/onva.js" target="_blank" rel="noreferrer">https://widgets.onva.io/onva.js</Link> and is written to be as flexible as possible for expansion and integration with existing systems. The source code for these widgets can be browsed at the <Link href="https://github.com/Onva-io/onva-widgets" target="_blank" rel="noreferrer">onva.io Github organisation page</Link>, where you are free to fork, contribute, or raise bugs.
                </p>
                
                <h2>Embedded survey</h2>
                <p>
                    The vanilla JS code for this example can be found at <Link href="https://widgets.onva.io/onva-simple.js" target="_blank" rel="noreferrer">https://widgets.onva.io/onva-simple.js</Link>.
                </p>
                <p>
                    You can embed a survey in any web page with two simple steps:
                    <ol>
                        <li>
                            <p>
                                Add a <code>&lt;div id="onva-survey"&gt;</code> container to your website in the place that you want the survey to appear. For example, in the main body of the page after an introduction.
                            </p>
                        </li>
                        <li>
                            <p>
                                Place the following snippet inside the <code>&lt;head&gt;</code> section of your website. The contents of the parameter <code>data-survey-uuid</code> identify the survey to be served.
                            </p>
                            <p>
                                <code>
                                    &lt;script src="https://widgets.onva.io/onva-simple.js" data-survey-uuid="{this.state.surveyUuid}" data-container-id="onva-survey"&gt;&lt;/script&gt;
                                </code>
                            </p>
                        </li>
                        <li>
                            <p>
                                Optional - you may add the extra parameter <code>data-redirect-uri</code> to the above snippet to have the user redirected to a different webpage upon completion of the survey.
                            </p>
                            <p>
                                <code>
                                    &lt;script src="https://widgets.onva.io/onva-simple.js" data-survey-uuid="{this.state.surveyUuid}" data-redirect-uri="https://www.onva.io/thanks/" data-container-id="onva-survey"&gt;&lt;/script&gt;
                                </code>
                            </p>
                        </li>
                    </ol>
                </p>
                <p>
                    All parameters understood by the embedded survey widget:

                    <dl>
                        <dt><code>data-container-id</code></dt>
                        <dd>The ID of the container to load the survey into.</dd>
                        <dt><code>data-survey-uuid</code></dt>
                        <dd>The unique identifier for the survey to serve. Can be found on the <Link to="/surveys/">Surveys page</Link></dd>
                        <dt><code>data-identifier</code></dt>
                        <dd>A unique identifier for the user submitting the survey if you have one. We highly recommend using a SHA1 hash of the user's email address, or phone number.</dd>
                        <dt><code>data-metadata</code></dt>
                        <dd>Key-value JSON metadata about the user submitting the survey.</dd>
                        <dt><code>data-locale</code></dt>
                        <dd>The two character locale of the user, which we will match against the survey content and deliver appropriate language.</dd>
                        <dt><code>data-redirect-uri</code></dt>
                        <dd>Upon completion of the survey redirect the user to this web address.</dd>
                    </dl>
                </p>

                <h2>Popup survey</h2>
                <p>
                    The vanilla JS code for this example can be found at <Link href="https://widgets.onva.io/onva-frame.js" target="_blank" rel="noreferrer">https://widgets.onva.io/onva-frame.js</Link>.
                </p>
                <p>
                    A simpler option is to have your survey appear in a pop-up dialog which the user will be asked to complete, and then optionally directed to a thank you page. The way to achieve this is similar to the embedded survey.
                    <ol>
                        <li>
                            <p>
                                Place the following snippet inside the <code>&lt;head&gt;</code> section of your website. The contents of the parameter <code>data-survey-uuid</code> identify the survey to be served.
                            </p>
                            <p>
                                <code>
                                    &lt;script src="https://widgets.onva.io/onva-frame.js" data-survey-uuid="{this.state.surveyUuid}"&gt;&lt;/script&gt;
                                </code>
                            </p>
                        </li>
                        <li>
                            <p>
                                Optional - you may add the extra parameter <code>data-redirect-uri</code> to the above snippet to have the user redirected to a different webpage upon completion of the survey.
                            </p>
                            <p>
                                <code>
                                    &lt;script src="https://widgets.onva.io/onva-frame.js" data-survey-uuid="{this.state.surveyUuid}" data-redirect-uri="https://www.onva.io/thanks/"&gt;&lt;/script&gt;
                                </code>
                            </p>
                        </li>
                    </ol>
                </p>
                <p>
                    All parameters understood by the popup survey widget:

                    <dl>
                        <dt><code>data-survey-uuid</code></dt>
                        <dd>The unique identifier for the survey to serve. Can be found on the <Link to="/surveys/">Surveys page</Link></dd>
                        <dt><code>data-defer</code></dt>
                        <dd>Whether to defer display of the survey. By default survey will be shown immediately, set to <code>true</code> to change this. The survey can be triggered using <code>window._onva.showFrame();</code></dd>
                        <dt><code>data-trigger-id</code></dt>
                        <dd>The id of a HTML element which when clicked will show the survey, e.g. <code>data-trigger-id="click-me"</code> as an attribute, and a button declared as <code>&lt;button id="click-me"&gt;Show survey&lt;/button&gt;</code>. Usually used in conjunction with <code>data-defer</code>.</dd>
                        <dt><code>data-identifier</code></dt>
                        <dd>A unique identifier for the user submitting the survey if you have one. We highly recommend using a SHA1 hash of the user's email address, or phone number.</dd>
                        <dt><code>data-metadata</code></dt>
                        <dd>Key-value JSON metadata about the user submitting the survey.</dd>
                        <dt><code>data-locale</code></dt>
                        <dd>The two character locale of the user, which we will match against the survey content and deliver appropriate language.</dd>
                        <dt><code>data-complete-callback</code></dt>
                        <dd>A Javascript snippet that will take precedence over <code>data-redirect-uri</code> to handle completion of the survey. Note that the frame will not be removed in this instance, and can be re-purposed.</dd>
                        <dt><code>data-redirect-uri</code></dt>
                        <dd>Upon completion of the survey redirect the user to this web address.</dd>
                    </dl>
                </p>

            </Fragment>
        );
    }
}

export default withDashboardLayout(withUtils(Widgets));
