import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutline';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import ContactSupportRoundedIcon from '@material-ui/icons/ContactSupportRounded';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import {
      NavLink
} from "react-router-dom";

const drawerWidthOpen = 240;

const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        width: drawerWidthOpen,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClosed: {
        /*
        width: drawerWidthClosed,
        flexShrink: 0,
        */
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(9) + 1,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    drawerList: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

export default function OnvaDrawer(props) {
    const classes = useStyles();

    return (
        <Drawer
            variant="permanent"
            className={props.open ? classes.drawerOpen : classes.drawerClosed}
            open={true}
            classes={{
                paper: props.open ? classes.drawerOpen : classes.drawerClosed
            }}
        >
            {/*
            <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            */}
            {/*<Divider/>*/}
            <Toolbar />
            <List className={classes.drawerList}>

                <ListItem button component={NavLink} exact to="/">
                    <ListItemIcon>
                        <HomeRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Home"/>
                </ListItem>

                <ListItem button component={NavLink} exact to="/quick-start/">
                    <ListItemIcon>
                        <PlayCircleFilledWhiteRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Quick Start"/>
                </ListItem>

                <ListItem button component={NavLink} to="/questions/" isActive={
                    (match, location) => {
                        return location.pathname.startsWith("/question/") || location.pathname.startsWith("/questions/");
                    }
                    }>
                    <ListItemIcon>
                        <HelpOutlineRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Questions"/>
                </ListItem>

                <ListItem button component={NavLink} to="/surveys/" isActive={
                    (match, location) => {
                        return location.pathname.startsWith("/survey/") || location.pathname.startsWith("/surveys/");
                    }
                    }>
                    <ListItemIcon>
                        <AssignmentRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Surveys"/>
                </ListItem>

                {/*
                <ListItem button component={NavLink} to="/reports/">
                    <ListItemIcon>
                        <BarChartRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Reports"/>
                </ListItem>
                */}

                <ListItem button component={NavLink} to="/embed/">
                    <ListItemIcon>
                        <CodeRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Embed"/>
                </ListItem>

                <Divider/>

                <ListItem button component={NavLink} to="/api-keys/">
                    <ListItemIcon>
                        <VpnKeyIcon/>
                    </ListItemIcon>
                    <ListItemText primary="API Keys"/>
                </ListItem>

                <ListItem button component={NavLink} to="/webhooks/">
                    <ListItemIcon>
                        <CloudUploadRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Webhooks"/>
                </ListItem>

                {/*
                <ListItem button component={NavLink} to="/categories/">
                    <ListItemIcon>
                        <CategoryRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Categories"/>
                </ListItem>
                */}

                <ListItem button component={NavLink} to="/help/">
                    <ListItemIcon>
                        <ContactSupportRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Help"/>
                </ListItem>

                {/*
                    TODO
                    - account details
                    - submissions?
                    - API keys
                    */}
            </List>
        </Drawer>
    );
}
