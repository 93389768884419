import withDashboardLayout from '../components/layout';
import { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import TableWrapper from '../components/table';
import Loading from '../components/loading';
import { friendlyDateTime, withUtils } from '../utils';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import EditIcon from '@material-ui/icons/Edit';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';

class Questions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alertOpen: true,
            questions: null,
            showQuestions: null,
            categories: null,
            tags: null,
            filterTags: [],
            filterCategories: [],
        };
    }

    componentDidMount() {
        const me = this;

        this.props.getSecure("/question/")
            .then(response => response.json())
            .then(data => {
                let tags = {};
                let cats = {};

                data.questions.forEach(function (question) {
                    question.tags.forEach(function (tag) {
                        if (tag.tag in tags) {
                            tags[tag.tag]++;
                        } else {
                            tags[tag.tag] = 1;
                        }
                    });

                    question.categories.forEach(function (category) {
                        if (category.category in cats) {
                            cats[category.category]++;
                        } else {
                            cats[category.category] = 1;
                        }
                    });
                });

                const unique_tags = Object.keys(tags);
                unique_tags.sort(function (a, b) {
                    return tags[b] - tags[a];
                });

                const unique_cats = Object.keys(cats);
                unique_cats.sort(function (a, b) {
                    return cats[b] - cats[a];
                });

                me.setState({
                    categories: unique_cats,
                    tags: unique_tags,
                    questions: data.questions,
                    showQuestions: data.questions,
                });
            });
    }

    updateFilteredQuestions() {
        const me = this;

        const questionHasTags = function (question) {
            if (!me.state.filterTags.length) {
                return true;
            }

            const matching = question.tags.filter(function (tag) {
                return me.state.filterTags.indexOf(tag.tag) !== -1;
            });

            return matching.length > 0;
        };

        const questionHasCategories = function (question) {
            if (!me.state.filterCategories.length) {
                return true;
            }

            const matching = question.categories.filter(function (category) {
                return me.state.filterCategories.indexOf(category.category) !== -1;
            });

            return matching.length > 0;
        };

        const showQuestions = this.state.questions.filter(function (question) {
            return questionHasTags(question) && questionHasCategories(question);

        });

        this.setState({
            showQuestions: showQuestions,
        });
    }

    addFilterTag(tag) {
        if (this.state.filterTags.indexOf(tag) === -1) {
            this.setState(
                {
                    filterTags: this.state.filterTags.concat([ tag ]),
                },
                this.updateFilteredQuestions,
            )
        }
    }

    removeFilterTag(tag) {
        const index = this.state.filterTags.indexOf(tag);

        if (index !== -1) {
            this.state.filterTags.splice(index);

            this.setState(
                {
                    filterTags: this.state.filterTags,
                },
                this.updateFilteredQuestions,
            )
        }
    }

    addFilterCategory(category) {
        if (this.state.filterCategories.indexOf(category) === -1) {
            this.setState(
                {
                    filterCategories: this.state.filterCategories.concat([ category ]),
                },
                this.updateFilteredQuestions,
            )
        }
    }

    removeFilterCategory(category) {
        const index = this.state.filterCategories.indexOf(category);

        if (index !== -1) {
            this.state.filterCategories.splice(index);

            this.setState(
                {
                    filterCategories: this.state.filterCategories,
                },
                this.updateFilteredQuestions,
            )
        }
    }

    render() {
        const fields = [
            {
                title: 'Question',
                key: 'content'
            },
            {
                title: 'Categories',
                key: 'categories'
            },
            {
                title: 'Tags',
                key: 'tags'
            },
            {
                title: 'Responses',
                key: 'response_stats'
            },
            {
                title: 'Active',
                key: 'active'
            },
            {
                title: 'Created',
                key: 'created_on',
            },
            {
                title: 'Last response',
                key: 'last_response_on',
            },
            {
                title: 'Actions',
                key: 'actions',
            },
        ];

        var rows = null;
        const use_locale = 'en';

        if (this.state.showQuestions !== null) {
            rows = this.state.showQuestions.map(function (question) {
                const locale = question.locales.filter(function (locale) {
                    return locale.locale === use_locale;
                }) || question.locales;

                var categories = question.categories.map(function (category) {
                    return category.category;
                });

                var tags = question.tags.map(function (tag) {
                    return tag.tag;
                });

                return {
                    content: (
                        <Link className="quiet" to={"/question/"+question.question_id}>
                            {locale[0].content}
                        </Link>
                    ),
                    response_stats: question.response_count + ' / ' + question.posed_count,
                    posed_count: question.posed_count,
                    num_answers: question.answers.length,
                    response_count: question.response_count,
                    categories: categories.map(function (category) { return (<Chip label={category} color="primary" />) }) || '-',
                    tags: tags.map(function (tag) { return (<Chip label={tag} color="secondary" />) }) || '-',
                    active: question.active ? 'Yes' : 'No',
                    created_on: friendlyDateTime(question.created_at),
                    last_response_on: friendlyDateTime(question.last_submission_at),
                    actions: (
                        <Fragment>
                            <Link to={"/question/"+question.question_id}>
                                <EditIcon/>
                            </Link>
                            <Link to={"/reports/question/" + question.question_id}>
                                <BarChartRoundedIcon/>
                            </Link>
                        </Fragment>
                    ),
                };
            });
        }

        const updated = (this.props.location.hash === '#updated');
        const created = (this.props.location.hash === '#created');
        const me = this;

        return (
            <div className="questions">
                {(updated || created) && (
                <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={() => this.setState({ alertOpen: false })}>
                    <MuiAlert severity="success" elevation={6} variant="filled">
                        {updated ? "Question updated" : "Question created"}
                    </MuiAlert>
                </Snackbar>
                )}
                <h1>Questions</h1>

                <p>
                    The core of any user research is, of course, the question. Here you can see questions that have been created, and reporting on responess as they come in.
                </p>

                <p>
                    <Button variant="contained" color="primary" component={Link} to="/question/">
                        Create question
                    </Button>
                </p>

                {
                    this.state.categories && (
                        <p>
                            Categories: {this.state.categories.map(function(category) {
                                return (
                                    <Chip
                                        key={"category-filter-"+category}
                                        onClick={() => me.addFilterCategory(category)}
                                        onDelete={() => me.removeFilterCategory(category)}
                                        label={category}
                                        color={me.state.filterCategories.indexOf(category) !== -1 ? "primary" : ""}
                                    />
                                );
                            })}
                        </p>
                    )
                }

                {
                    this.state.tags && (
                        <p>
                            Tags: {this.state.tags.map(function(tag) {
                                return (
                                    <Chip
                                        key={"tag-filter-"+tag}
                                        onClick={() => me.addFilterTag(tag)}
                                        onDelete={() => me.removeFilterTag(tag)}
                                        label={tag}
                                        color={me.state.filterTags.indexOf(tag) !== -1 ? "primary" : ""}
                                    />
                                );
                            })}
                        </p>
                    )
                }

                {
                    rows == null ? 
                        (<Loading open={true} />) :
                        (<TableWrapper fields={fields} rows={rows}/>)
                }
            </div>
        );
    }
}

export default withDashboardLayout(withUtils(Questions));
