import withDashboardLayout from '../components/layout';
import { Component, Fragment } from 'react';
import { withUtils } from '../utils';
import { Link } from "react-router-dom";

class Help extends Component {
    render() {
        return (
            <Fragment>
                <h1>Help!</h1>
                <p>
                    While we're setting up the community, help centre, and support channels feel free to reach out to <Link href="mailto:support@onva.io">support@onva.io</Link> with any problems you have an we'll try and answer your questions!
                </p>
                <h2>Knowledge Base</h2>
                <p>
                    Common problems that occur will be added to the <Link to="https://onva.freshdesk.com/support/home">Onva support centre knowledge base</Link> which may be the quickest way to resolve your issue.
                </p>
            </Fragment>
        );
    }
}

export default withDashboardLayout(withUtils(Help));
