import withDashboardLayout from '../components/layout';
import { Component } from 'react';
import { withUtils, friendlyDateTime } from '../utils';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../components/loading';
import { withStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    blurb: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    apikeyDetails: {
        display: 'block',
    },
});

class ApiKeyUsageDetails extends Component {
    render() {
        return (
            <div>
                <p>
                    To use this API key, pass the following HTTP header to the API endpoints at <code>{process.env.REACT_APP_API_BASE}</code>. API documentation can be browsed with <a href={process.env.REACT_APP_API_BASE + "/redoc/"} target="_blank" rel="noreferrer">Redoc</a> or <a href={process.env.REACT_APP_API_BASE + "/docs/"} target="_blank" rel="noreferrer">Swagger</a>.
                </p>
                <p>
                    <code>
                        X-API-Key: {this.props.api_key}
                    </code>
                </p>
            </div>
        );
    }
}

class ApiKeyCreatedDialog extends Component {
    handleClose() {
        this.props.onClose();
    }

    /*
    async createApiKey(e) {
        e.preventDefault();

        const payload = {};

        const response = await this.props.postSecure('/apikey/', payload);

        if (response.ok) {
            this.setState({
                redirect: '/api-keys/#created',
            });
        } else {
            const data = await response.json();
            console.log(data);

            this.setState({
                error: data.name,
            });
        }
    }
    */

    render() {
        return (
            <Dialog onClose={() => this.handleClose()} aria-labelledby="created-apikey-title" open={this.props.open}>
                <DialogTitle id="edit-apikey-title">API key created!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You created a new API key, something something, blurb about using it
                    </DialogContentText>
                    <DialogContentText>
                        <ApiKeyUsageDetails api_key={this.props.api_key}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class ApiKeyAccordian extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Accordion key={"apikey-" + this.props.api_key}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={"apikey-header-"+this.props.api_key}
                >
                    <Typography className={classes.heading}>{this.props.api_key.substr(0, 5)}...{this.props.api_key.substr(this.props.api_key.length - 5)}</Typography>
                    <Typography className={classes.secondaryHeading}>{friendlyDateTime(this.props.created_at)}</Typography>

                </AccordionSummary>
                <AccordionDetails className={classes.apikeyDetails}>
                    <ApiKeyUsageDetails api_key={this.props.api_key}/>
                </AccordionDetails>
            </Accordion>
        );
    }
}

class ApiKeys extends Component {
    constructor(props) {
        super(props)

        this.state = {
            alertOpen: false,
            api_keys: null,
            loading: false,
            createApiKey: null,
        };
    }

    async componentDidMount() {
        const response = await this.props.getSecure('/key/');
        const data = await response.json();

        if (response.ok) {
            this.setState({
                api_keys: data.api_keys,
            });
        } else {
            window.alert('Something bad happened, try refreshing.');
            console.log(data);
        }
    }

    async createApiKey() {
        this.setState(
            {
                loading: true,
            },
            async function() {
                const response = await this.props.postSecure('/key/', {});
                const data = await response.json();

                let newState = {
                    loading: false,
                };

                if (response.ok) {
                    newState['createdApiKey'] = data.api_key;
                    newState['api_keys'] = this.state.api_keys.concat([ data ]);

                } else {
                    window.alert('Something bad happened, try again.');
                    console.log(data);
                }

                this.setState(newState);
            }
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className="api_keys">
                <ApiKeyCreatedDialog
                    api_key={this.state.createdApiKey}
                    open={this.state.createdApiKey != null}
                    onClose={() => this.setState({ createdApiKey: null })}
                />

                <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={() => this.setState({ alertOpen: false })}>
                    <MuiAlert severity="success" elevation={6} variant="filled">
                        API key created
                    </MuiAlert>
                </Snackbar>
                <h1>
                    API keys
                </h1>
                <Typography className={classes.blurb}>
                    API Keys are dangerous! With an API key you have the same level of access as the currently logged in user, and can perform all of the actions that you would be able to perform through this dashboard. Possibly more. Do not share these keys with anybody unless you trust them.
                </Typography>
                <Typography className={classes.blurb}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.createApiKey()}
                    >
                        Create API key
                    </Button>
                </Typography>
                {
                    this.state.api_keys === null ? 
                        (<Loading open={true}/>) :
                        this.state.api_keys.length === 0 ?
                        (<Typography>No API keys defined - yet!</Typography>) :
                        (this.state.api_keys.map(function (apikey) {
                            return (
                                <ApiKeyAccordian {...apikey} />
                            );
                        }))
                }
            </div>
        );
    }
}

ApiKeyAccordian = withStyles(styles)(ApiKeyAccordian);

export default withDashboardLayout(withUtils(withStyles(styles)(ApiKeys)));
