import withDashboardLayout from '../components/layout';
import { Component } from 'react';
import { friendlyDateTime, withUtils } from '../utils';
import { Link } from "react-router-dom";
import Alert from '../components/alerts';
import TableWrapper from '../components/table';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

class Categories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: null
        };
    }

    async componentDidMount() {
        const response = await this.props.getSecure("/categories/");
        const data = await response.json();

        this.setState({
            categories: data.categories
        });
    }

    render() {
        const fields = [
            {
                title: 'Category',
                key: 'category'
            },
            {
                title: 'Created at',
                key: 'created_on',
            },
        ];

        var rows = null;

        if (this.state.categories !== null) {
            rows = this.state.categories.map(function (category) {
                return {
                    category: (<Link to={"/category/"+category.category_id}>{category.category}</Link>),
                    created_on: friendlyDateTime(category.created_at),
                };
            });
        }

        const updated = (this.props.location.hash === '#updated');
        const created = (this.props.location.hash === '#created');

        return (
            <div className="categories">
                {updated && (<Alert severity="success" content="Category updated"/>)}
                {created && (<Alert severity="success" content="Category created"/>)}
                <h1>Categories</h1>

                <p>
                    <Link to="/category/" className="btn">Create category</Link>
                </p>

                {
                    rows == null ? 
                        (<Backdrop open={true}><CircularProgress /></Backdrop>) :
                        (<TableWrapper fields={fields} rows={rows}/>)
                }
            </div>
        );
    }
}

export default withDashboardLayout(withUtils(Categories));
