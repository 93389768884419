import { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class TableWrapper extends Component {
    render() {
        var me = this;

        return (
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {me.props.fields.map(function (field, index) {
                                return (<TableCell className={field.className}>{field.title}</TableCell>);
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {me.props.rows.map(function (row, rowIndex) {
                        return (
                            <TableRow key={rowIndex}>
                            {me.props.fields.map(function (field, fieldIndex) {
                                return (<TableCell className={field.className}>{ row[field.key] }</TableCell>);
                            })}
                            </TableRow>
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default TableWrapper;
