import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import Avatar from '@material-ui/core/Avatar';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
    appBar: {
        display: "flex",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    headerImage: {
        height: 30,
    },
    profileImage: {
        height: 40,
        borderRadius: '100%',
        marginRight: theme.spacing(2),
    },
    profile: {
        flex: 1,
        textAlign: 'right',
    },
    profileName: {
        marginLeft: theme.spacing(2),
    }
}));

export default function MenuAppBar(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { user } = useAuth0();

    const handleMenu = (event) => {
        // setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => props.toggleDrawer()}
                >
                    <MenuIcon />
                </IconButton>
                <img src="https://static.onva.io/logo-white-126x30.png" className={classes.headerImage} alt="onva"/>
                <div className={classes.profile}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <Avatar className={classes.profileAvatar} alt={user.name} src={user.picture} />
                        <Typography className={classes.profileName}>
                            {user.name}
                        </Typography>
                        {/* <ArrowDropDownRoundedIcon/> */}
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
}
