import withDashboardLayout from '../components/layout';
import { Link } from "react-router-dom";
import { Fragment, Component } from 'react';
import NPSSurvey from '../components/nps-survey';
// import SentimentSurvey from '../components/sentiment-survey';
import { withUtils } from '../utils';
import { withStyles } from "@material-ui/core/styles";
import Loading from '../components/loading';

const styles = theme => ({
});

class QuickStart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            surveys: null,
            npsSurvey: null,
            sentimentSurvey: null,
        };
    }

    async componentDidMount() {
        const me = this;

        this.props.getSecure('/survey/')
            .then(response => response.json())
            .then(data => {
                const npsSurveys = data.surveys.filter(
                    function(survey) {
                        const tags = survey.tags.map(function(tag) { return tag.tag });
                        return tags.indexOf('nps') >= 0
                    }
                );

                let npsSurvey = npsSurveys.length ? npsSurveys[0] : false;

                const sentimentSurveys = data.surveys.filter(
                    function(survey) {
                        const tags = survey.tags.map(function(tag) { return tag.tag });
                        return tags.indexOf('sentiment') >= 0
                    }
                );

                let sentimentSurvey = sentimentSurveys.length ? sentimentSurveys[0] : false;

                me.setState({
                    surveys: data.surveys,
                    npsSurvey: npsSurvey,
                    sentimentSurvey: sentimentSurvey,
                });
            });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.quickstart}>
                <h1>Quick Start</h1>

                {
                    this.state.surveys === null ?
                    (<Loading open={true}/>) :
                    (
                        <Fragment>
                            <h2>NPS Survey</h2>
                            <p>
                                One of the simplest and quickest ways to get started using the Onva platform is to implement an Net Promoter Score survey, or NPS. An NPS survey is a simple 1 question survey intended to gauge how likely a user is to recommend the product that they're currently using.
                            </p>
                            <p>
                                Read more about <Link to="https://en.wikipedia.org/wiki/Net_Promoter" target="_blank" rel="noreferrer">Net Promoter Score at Wikipedia</Link>
                            </p>
                            <NPSSurvey survey={this.state.npsSurvey} onCreate={(survey) => this.setState({ npsSurvey: survey })}/>
                            {/*
                            <h2>Sentiment widget</h2>
                            <p>
                                A simple happy or sad face can tell you a lot about how your users feel about your product, and it's easy to integrate this also.
                            </p>
                            <SentimentSurvey survey={this.state.sentimentSurvey}/>
                            */}
                        </Fragment>
                    )
                }

                <h2>Create our own survey</h2>
                <p>
                    We're going to have a video to talk through this, but until we have that here's a step by step guide on creating your own survey for the first time, and hooking it up to our backend so that we can reward our faithful users.
                </p>
                <h3>Decide on your questions</h3>
                <p>
                    Before we can start building anything we need to decide what we want to ask our users. A few best practices when planning out your questions:
                </p>
                <ul>
                    <li>It's encouraged to think on a broad scale when planning out your questions, and to leverage the number of people who are going to answer questions to get an expansive view of the crowd rather than an individual.</li>
                    <li>Keep in mind that the more questions you ask people in a single sitting, the less likely they're going to complete the session, or less likely to answer again. People will repeat!</li>
                </ul>
                <p>
                    We're going to focus on premium subscription for our imaginary business. Our questions will be:
                </p>
                <ol>
                    <li>Is the cost of subscription good value for money?</li>
                    <li>Were the benefits of subscription clear?</li>
                    <li>Would you take advantage of a referral system if it was available?</li>
                    <li>...</li>
                </ol>
                <h3>Create the questions in the dashboard</h3>
                <p>
                    Once you have your questions and answers you can create them using the dashboard tool. Setting them all as having an equal priority will mean that they are each chosen more-or-less randomly to be answered by users.
                </p>
                <p>
                    We'll set the category to "onboarding" so that we can use this when constructing our survey. When a survey is choosing questions it will filter by category, and then by tag.
                </p>
                <p>
                    Currently the dashboard is lacking a few features such as multi-language, question dependencies, etc. but they will be coming soon.
                </p>
                <h3>Create the survey</h3>
                <p>
                    Now we have our questions created we can go ahead and create the survey rules which determine how they are chosen and targetted.
                </p>
                <p>
                    Ensure that category is set to the same as was specified when creating the questions, and we can be sure that the correct questions will be chosen when serving. For this example we will leave the question count as 2, so that users will only have to answer 2 of our questions to get their reward.
                </p>
                <p>
                    Currently the dashboard is lacking a few features such as question targeting, survey metadata, etc. but they will be coming soon.
                </p>
                <h3>Reward the user</h3>
                <p>
                    At this stage we can setup a webhook so that every time a user completes submission of this survey we will receive a notification and we can reward them appropriately. This is a relatively complex topic and will require a more detailed explanation.
                </p>
                <h3>Embed the survey</h3>
                <p>
                    It is possible to embed the survey that was just created using the widgets detailed in the <Link to="/embed/">Embed page</Link>, which goes into some detail on the topic. An important thing to note is that identification of the user submitting the survey is important, particularly if you want to reward the user on completion.
                </p>

            </div>
        );
    }
}

export default withDashboardLayout(withUtils(withStyles(styles)(QuickStart)));
