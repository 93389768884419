import { Component } from 'react';
import { Bar, Line } from 'react-chartjs-2';

class QuestionDailyResponses extends Component {
    constructor(props) {
        super(props);

        const use_locale = 'en';
        const use_answer_values = true;

        let answer_data = {};
        let dates = [];
        const as_percentages = true;

        props.report.forEach(function(report) {
            dates.push(new Date(report.date));

            let dailyTotal = 0;

            report.responses.forEach(function(response) {
                if (!answer_data[response.answer_id]) {
                    answer_data[response.answer_id] = [];
                }

                dailyTotal += response.response_count;
                answer_data[response.answer_id].push(response.response_count);
            });
            
            if (as_percentages) {
                // normalize response data
                report.responses.forEach(function(response) {
                    const len = answer_data[response.answer_id].length;

                    if (dailyTotal > 0) {
                        answer_data[response.answer_id][len-1] = 100 * (answer_data[response.answer_id][len-1] / dailyTotal);
                    } else {
                        answer_data[response.answer_id][len-1] = 100 / props.question.answers.length;
                    }
                });
            }
        });

        function makeRange(min, max, slices) {
            var delta = (max - min) / (slices - 1);
            let range = [];

            while (slices > 0) {
                range.push(min);
                min += delta;
                slices -= 1;
            }

            return range;
        }

        var red = 255;

        // green focus around 77
        var greens = makeRange(0, 154, props.question.answers.length);

        // blues focus around 86
        var blues = makeRange(0, 172, props.question.answers.length);

        let datasets = props.question.answers.map(function (answer, index) {
            let label = answer.value;

            if (!use_answer_values) {
                const locale = answer.locales.filter(function (locale) {
                    return locale.locale === use_locale;
                });

                label = locale[0].content;
            }

            const colour = 'rgb(' + red + ', ' + greens[index] + ', ' + blues[index] + ')';

            return {
                backgroundColor: colour.replace(")", ", 0.5)"),
                borderColor: colour,
                data: answer_data[answer.answer_id],
                label: label,
                fill: true,
            };
        });

        const data = {
            labels: dates,
            datasets: datasets,
        };

        const options = {
            //maintainAspectRatio: false,
            spanGaps: false,
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return data.datasets[tooltipItem.datasetIndex].label + ': ' + Math.round(tooltipItem.yLabel) + '%';
                    }
                },
                //position: position,
                mode: 'index',
                intersect: false,
            },
            elements: {
                line: {
                    tension: 0
                }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        parser: 'YYYY-MM-DD',
                        round: 'day',
                        tooltipFormat: 'll'
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Date'
                    }
                }],
                yAxes: [{
                    ticks: {
                        min: 0,
                        max: 100,
                        callback: function(value){return value+ "%"}
                    },  
                    scaleLabel: {
                        display: true,
                        labelString: "Percentage"
                    },
                    stacked: true
                }]
            }
        };

        this.state = {
            data: data,
            options: options,
        };
    }

    render() {
        return (
            <Line
                options={this.state.options}
                data={this.state.data}
                width={this.props.width}
                height={this.props.height}
            />
        )
    }
}

class QuestionResponses extends Component {
    constructor(props) {
        super(props);

        const use_locale = 'en';
        const use_answer_values = true;

        const locale = props.question.locales.filter(function (locale) {
            return locale.locale === use_locale;
        });

        const labels = props.question.answers.map(function (answer) {
            if (use_answer_values) {
                return answer.value;
            }

            const locale = answer.locales.filter(function (locale) {
                return locale.locale === use_locale;
            });

            return locale[0].content;
        });

        const values = props.question.answers.map(function (answer) {
            return answer.response_count;
        });

        const data = {
            labels: labels,
            datasets: [
                {
                    label: locale[0].content,
                    backgroundColor: 'rgba(255, 77, 86, 0.5)',
                    borderColor: 'rgb(255, 77, 86)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255, 77, 86, 0.8)',
                    hoverBorderColor: 'rgb(255, 77, 86)',
                    data: values,
                }
            ]
        };

        const options = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function(value) {if (value % 1 === 0) {return value;}}
                    }
                }]
            }
        };

        this.state = {
            data: data,
            options: options,
        };
    }

    render() {
        return (
            <Bar
                options={this.state.options}
                data={this.state.data}
                width={this.props.width}
                height={this.props.height}
            />
        )
    }
}

export {
    QuestionResponses,
    QuestionDailyResponses,
};
