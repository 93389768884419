import { withAuth0 } from '@auth0/auth0-react';
import { withRouter } from "react-router";
import { Component } from 'react';
import Loading from './components/loading';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

function friendlyDateTime(date) {
    if (!date) {
        return '-';
    }

    const odate = new Date(date);
    const fdate = friendlyDate(odate);
    const time = odate.toLocaleTimeString(
        [],
        {
            hour: '2-digit',
            minute:'2-digit'
        }
    );

    return `${fdate} @ ${time}`;
}

function friendlyDate(date) {
    if (!date) {
        return '-';
    }

    const today = new Date();
    const odate = new Date(date);

    if (
        odate.getFullYear() === today.getFullYear() &&
        odate.getMonth() === today.getMonth() &&
        odate.getDate() === today.getDate()
    ) {
        return "Today";
    }

    const yesterday = today;
    yesterday.setDate(yesterday.getDate() - 1);

    if (
        odate.getFullYear() === yesterday.getFullYear() &&
        odate.getMonth() === yesterday.getMonth() &&
        odate.getDate() === yesterday.getDate()
    ) {
        return "Yesterday";
    }

    var friendly = `${odate.getDate()} ${months[odate.getMonth()]}`;

    if (odate.getFullYear() !== today.getFullYear()) {
        friendly += ` ${odate.getFullYear()}`;
    }

    return friendly;
}

/*
function handleFetchErrors(response) {
    if (!response.ok) {
        throw Error(response.status);
    }

    return response;
}
*/

function withUtils(OldComponent) {
    class NewComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                lastStatus: 0,
            };

            this.fetchSecure = this.fetchSecure.bind(this);
        }

        async postSecure(url, data) {
            return await this.fetchSecure(
                url,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );
        }

        async deleteSecure(url) {
            return await this.fetchSecure(
                url,
                {
                    method: 'DELETE',
                }
            );
        }

        async putSecure(url, data) {
            return await this.fetchSecure(
                url,
                {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );
        }

        async patchSecure(url, data) {
            return await this.fetchSecure(
                url,
                {
                    method: 'PATCH',
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );
        }

        async getSecure(url, opts = {}) {
            return await this.fetchSecure(url, opts);
        }

        async fetchSecure(url, opts = {}) {
            const { getAccessTokenSilently } = this.props.auth0;
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            });

            if (!opts.headers) {
                opts.headers = {};
            }

            opts.headers.Authorization = `Bearer ${token}`;

            const response = await fetch(
                `${process.env.REACT_APP_API_BASE}${url}`,
                opts
            );

            this.setState({
                lastStatus: response.status,
            });

            return response;
        }

        render() {
            const { isLoading, error } = this.props.auth0;

            if (isLoading) {
                return (<Loading open={true} />);
            }

            if (error) {
                return (<p>Something went wrong - {error.message}</p>);
            }

            if (this.state.lastStatus === 401 || this.state.lastStatus === 403) {
                return (<p>Authentication error</p>);
            }

            if (this.state.lastStatus === 500) {
                return (<p>Internal server error</p>);
            }

            return (
                <OldComponent
                    getSecure={this.getSecure}
                    fetchSecure={this.fetchSecure}
                    postSecure={this.postSecure}
                    patchSecure={this.patchSecure}
                    putSecure={this.putSecure}
                    deleteSecure={this.deleteSecure}
                    {...this.props}
                />
            );
        }
    }

    return withAuth0(withRouter(NewComponent));
}

export {
    withUtils,
    friendlyDate,
    friendlyDateTime,
};
