import withDashboardLayout from '../components/layout';
import { Component } from 'react';
import { withUtils } from '../utils';
import { NotFound } from '../views';
import Loading from '../components/loading';
import { QuestionDailyResponses } from '../components/question-reports';

class QuestionDailyReport extends Component {
    constructor(props) {
        super(props);

        const questionId = props.match.params.questionId;

        this.state = {
            questionId: questionId,
            question: null,
            report: null,
        };
    }

    async componentDidMount() {
        if (this.state.question === null) {
            const me = this;

            let response = await this.props.getSecure(`/question/${this.state.questionId}`);

            if (response.status === 404) {
                me.setState({
                    question: false,
                });
            } else {
                const question = await response.json();

                response = await this.props.getSecure(`/question/${this.state.questionId}/daily/`);

                const report = await response.json();

                me.setState({
                    question: question,
                    report: report.report,
                });
            }
        }
    }

    render() {
        if (this.state.question === false) {
            return <NotFound/>
        }

        if (this.state.question === null) {
            return (
                <Loading open={true} />
            );
        }

        return (
            <div className="question-report">
                <h1>Question responses</h1>
                <ul>
                    <li>add a question filter so that we can choose different questions</li>
                    <li>maybe a report type dropdown?</li>
                </ul>

                <QuestionDailyResponses
                    question={this.state.question}
                    report={this.state.report}
                />
            </div>
        )
    }
}

export default withDashboardLayout(withUtils(QuestionDailyReport));
