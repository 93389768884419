import withDashboardLayout from '../components/layout';
import { Fragment, Component } from 'react';
import { withUtils, friendlyDateTime } from '../utils';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../components/loading';
import { withStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles = theme => ({
    blurb: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    webhookDetails: {
        display: 'block',
    },
});

class WebhookCreateDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            name: props.name || "",
            active: props.active === undefined ? true : props.active,
            endpoint: props.endpoint || "",
            shared_key: props.shared_key || "",
        };
    }

    handleClose() {
        this.props.onClose();
    }

    async createWebhook(e) {
        e.preventDefault();

        const me = this;

        this.setState(
            {
                loading: true,
            },
            async function() {
                const payload = {
                    name: me.state.name,
                    active: me.state.active,
                    endpoint: me.state.endpoint,
                    shared_key: me.state.shared_key,
                };

                const response = await me.props.postSecure('/webhooks/', payload);

                if (response.ok) {
                    me.setState(
                        {
                            loading: false,
                        },
                        function () {
                            me.props.onCreate(me.state);
                        }
                    );
                } else {
                    const data = await response.json();
                    console.log(data);

                    me.setState({
                        error: data.name,
                        loading: false,
                    });
                }
            }
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <Dialog onClose={() => this.handleClose()} aria-labelledby="create-webhook-title" open={this.props.open}>
                    <DialogTitle id="edit-webhook-title">Create webhook</DialogTitle>
                    <DialogContent>
                        <Loading open={this.state.loading}/>
                        {this.state.error && (<Alert severity="error">{this.state.error}</Alert>)}
                        <form onSubmit={(e) => this.createWebhook(e)}>
                            <WebhookForm
                                name={this.state.name}
                                active={this.state.active}
                                endpoint={this.state.endpoint}
                                shared_key={this.state.shared_key}
                                onChange={(state) => this.setState(state)}
                            />
                            <Typography className={classes.blurb}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={this.state.loading}
                                >
                                    Create webhook
                                </Button>
                            </Typography>
                        </form>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

class WebhookAccordian extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            showName: props.name,
            showEndpoint: props.endpoint,

            webhook_id: props.webhook_id,
            name: props.name,
            endpoint: props.endpoint,
            active: props.active,
            shared_key: props.shared_key,
            updated: false,
        };
    }

    updateState(opts) {
        this.setState({
            updated: true,
            ...opts,
        });
    }

    async updateWebhook(e) {
        e.preventDefault();
        const me = this;

        if (this.state.updated) {
            this.setState(
                {
                    loading: true,
                },
                async function() {
                    const payload = {
                        name: me.state.name,
                        endpoint: me.state.endpoint,
                        active: me.state.active,
                        shared_key: me.state.shared_key,
                    };

                    const response = await me.props.putSecure('/webhooks/' + me.state.webhook_id, payload);
                    const data = await response.json();

                    if (response.ok) {
                        me.setState(
                            {
                                loading: false,
                                updated: false,
                                showName: data.name,
                                showEndpoint: data.endpoint,
                                ...data,
                            },
                            function(){
                                me.props.onUpdate(me.state);
                            },
                        );
                    } else {
                        console.log(data);

                        me.setState({
                            loading: false,
                            error: data.name,
                        });
                    }
                }
            );
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <Loading open={this.state.loading}/>
                <Accordion key={"webhook-" + this.state.webhook_id}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={"webhook-header-"+this.state.webhook_id}
                    >
                        <Typography className={classes.heading}>{this.state.showName}</Typography>
                        <Typography className={classes.secondaryHeading}>{this.state.showEndpoint}</Typography>

                    </AccordionSummary>
                    <AccordionDetails className={classes.webhookDetails}>
                        {this.state.error && (<Alert severity="error">{this.state.error}</Alert>)}
                        <form onSubmit={(e) => this.updateWebhook(e)}>
                            <WebhookForm
                                name={this.state.name}
                                active={this.state.active}
                                endpoint={this.state.endpoint}
                                shared_key={this.state.shared_key}
                                onChange={(state) => this.updateState(state)}
                            />
                            <Typography>
                                <Button variant="contained" color="primary" disabled={!this.state.updated} type="submit">Update webhook</Button>
                            </Typography>
                            <List>
                                <ListItemText primary={"Last status code was " + (this.state.last_status_code || '-')}/>
                            </List>
                            <List>
                                <ListItemText primary={"Last delivery at " + friendlyDateTime(this.state.last_delivery_at)}/>
                            </List>
                            <List>
                                <ListItemText primary={"Created at " + friendlyDateTime(this.state.created_at)}/>
                            </List>
                            <List>
                                <ListItemText primary={"Updated at " + friendlyDateTime(this.state.updated_at)}/>
                            </List>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </Fragment>
        );
    }
}

class WebhookForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name,
            active: props.active === undefined ? true : props.active,
            endpoint: props.endpoint,
            shared_key: props.shared_key,
        };
    }

    updateState(opts) {
        this.setState(
            opts,
            function() {
                if (this.props.onChange) {
                    this.props.onChange(this.state);

                } else {
                    console.warn('probably want an onChange handler here');
                }
            }
        );
    }

    render() {
        return (
            <div className="webhook-form">
                <div className="flex-group">
                    <FormControl>
                        <TextField required id="name" label="Name" value={this.state.name} onChange={(e) => this.updateState({ name: e.target.value })} />
                        <FormHelperText>Internal name for this webhook</FormHelperText>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.active}
                                onChange={(e) => this.updateState({ active: e.target.checked })}
                                name="active"
                                color="primary"
                            />
                        }
                        label="Active?"
                    />
                </div>
                <div className="flex-group">
                    <FormControl>
                        <TextField required id="endpoint" pattern="https://.*" label="Endpoint" type="url" value={this.state.endpoint} onChange={(e) => this.updateState({ endpoint: e.target.value })} />
                        <FormHelperText>Endpoint for this webhook, must be https://</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <TextField required id="shared_key" label="Shared key" value={this.state.shared_key} onChange={(e) => this.updateState({ shared_key: e.target.value })} />
                        <FormHelperText>A shared key we will provide with every webhook to verify its source</FormHelperText>
                    </FormControl>
                </div>
            </div>
        );
    }
}

class Webhooks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            alertOpenFor: null,
            webhooks: null,
            showCreate: false,
            loading: false,
        };
    }

    async componentDidMount() {
        const me = this;

        this.setState(
            {
                loading: true,
            },
            function() {
                this.props.getSecure('/webhooks/')
                    .then(response => response.json())
                    .then(data => {
                        me.setState({
                            webhooks: data.webhooks,
                            loading: false,
                        });
                    });
            }
        );
    }

    addWebhook(webhook) {
        let webhooks = this.state.webhooks;
        webhooks.push(webhook);

        this.setState({
            alertOpenFor: 'created',
            showCreate: false,
            webhooks: webhooks,
        });
    }

    render() {
        const { classes } = this.props;

        const alertMessages = {
            'created': 'Webhook created',
            'updated': 'Webhook updated',
        };

        const me = this;

        return (
            <div className="webhooks">
                <WebhookCreateDialog
                    open={this.state.showCreate}
                    onCreate={(webhook) => this.addWebhook(webhook)}
                    onClose={() => this.setState({ showCreate: false })}
                />

                <Snackbar open={this.state.alertOpenFor !== null} autoHideDuration={3000} onClose={() => this.setState({ alertOpenFor: null })}>
                    <MuiAlert severity="success" elevation={6} variant="filled">
                        {this.state.alertOpenFor && alertMessages[this.state.alertOpenFor]}
                    </MuiAlert>
                </Snackbar>
                <h1>
                    Webhooks
                </h1>
                <Typography className={classes.blurb}>
                    When creating a new webhook or modifying the endpoint of an existing endpoint we will attempt to validate how the endpoint handles the webhook shared key. Two requests will be sent to endpoint, one with the correct shared key, and one without. The endpoint must respond with a 2xx status code for the request with the correct shared key, and a non-2xx status code for the incorrect secret key.
                </Typography>
                <Typography className={classes.blurb}>
                    We only accept HTTPS endpoints due to the potentially sensitive nature of the payload.
                </Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<HelpOutlineIcon />}
                        aria-controls="panel1a-content"
                    >
                        <Typography>Getting started with webhooks</Typography>

                    </AccordionSummary>
                    <AccordionDetails className={classes.webhookDetails}>
                        <Typography className={classes.blurb}>
                            We have an <a href="https://github.com/Onva-io/onva-webhook-example" target="_blank" rel="noreferrer">example webhook processor</a> available in our Github repository that you're welcome to use during development, or as a basis for your own webhook processor.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<HelpOutlineIcon />}
                        aria-controls="panel1a-content"
                    >
                        <Typography>How can I test while developing locally?</Typography>

                    </AccordionSummary>
                    <AccordionDetails className={classes.webhookDetails}>
                        <div>
                        <Typography className={classes.blurb}>
                            You can expose your localhost by running <code>ssh -R 80:localhost:4567 nokey@localhost.run</code>, replacing <code>4567</code> with the port number that your local development environment is running on.  Because ssh is already installed on all major operating systems you do not need to download a client.
                        </Typography>
                        <Typography className={classes.blurb}>
                            You will see a line that looks similar to this:
                        </Typography>
                        <Typography className={classes.blurb}>
                            <pre>bf5021bacbc63e.localhost.run tunneled with tls termination, https://bf5021bacbc63e.localhost.run</pre>
                        </Typography>
                        <Typography className={classes.blurb}>
                            Copy the https:// url (e.g. https://bf5021bacbc63e.localhost.run) and paste it into the webhook's
                            endpoint field.
                        </Typography>
                        <Typography className={classes.blurb}>
                            Onva can now send webhooks to that address and localhost.run will tunnel them to your local development environment on the port specified in the <code>ssh</code> command.
                        </Typography>
                        <Typography className={classes.blurb}>
                            For more information on localhost.run see the <a href="https://localhost.run" target="_blank" rel="noreferrer"> localhost.run website</a>.
                        </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Typography className={classes.blurb}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({ showCreate: true })}
                    >Create webhook</Button>
                </Typography>
                {
                    this.state.webhooks === null ? 
                        (<Loading open={true}/>) :
                        this.state.webhooks.length === 0 ?
                        (<Typography>No webhooks defined - yet!</Typography>) :
                        (this.state.webhooks.map(function (webhook) {
                            return (
                                <WebhookAccordian
                                    {...webhook}
                                    onUpdate={() => me.setState({ alertOpenFor: 'updated' })}
                                />
                            );
                        }))
                }
            </div>
        );
    }
}

WebhookCreateDialog = withUtils(withStyles(styles)(WebhookCreateDialog));
WebhookAccordian = withUtils(withStyles(styles)(WebhookAccordian));

export default withDashboardLayout(withUtils(withStyles(styles)(Webhooks)));
