import { Component } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import {
    Home,
    NotFound,
    Categories,
    Help,
    Questions,
    QuestionEdit,
    Surveys,
    ApiKeys,
    Webhooks,
    Embed,
    QuickStart,
} from './views';
import QuestionReport from './views/question-report';
import QuestionDailyReport from './views/question-daily-report';
import {
      BrowserRouter as Router,
      Switch,
      Route,
} from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import './app.scss';
import './rollbar';

const styles = theme => ({
    root: {
        /*
        flexGrow: 1,
        zIndex: 1,
        overflow: "hidden",
        position: "relative",
        */
        display: "flex"
    },
});

class App extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/embed/:surveyUuid">
                            <Embed />
                        </Route>
                        <Route exact path="/quick-start/">
                            <QuickStart />
                        </Route>
                        <Route exact path="/embed/">
                            <Embed />
                        </Route>
                        <Route exact path="/question/">
                            <QuestionEdit />
                        </Route>
                        <Route path="/question/:questionId">
                            <QuestionEdit />
                        </Route>
                        <Route exact path="/questions/">
                            <Questions />
                        </Route>
                        <Route exact path="/surveys/">
                            <Surveys />
                        </Route>
                        <Route path="/reports/question/:questionId/daily/">
                            <QuestionDailyReport />
                        </Route>
                        <Route path="/reports/question/:questionId">
                            <QuestionReport />
                        </Route>
                        <Route exact path="/api-keys/">
                            <ApiKeys />
                        </Route>
                        <Route exact path="/webhooks/">
                            <Webhooks />
                        </Route>
                        <Route exact path="/categories/">
                            <Categories />
                        </Route>
                        <Route path="/help/">
                            <Help />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default withAuth0(withStyles(styles)(App));
