import { Component } from 'react';

class Alert extends Component {
    render() {
        return (
            <div className={"alert " + this.props.style}>
                <p>{this.props.content}</p>
            </div>
        );
    }
}

export default Alert;
