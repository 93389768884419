import withDashboardLayout from '../components/layout';
import { Fragment, Component } from 'react';
import { Link } from "react-router-dom";
import { withUtils, friendlyDateTime } from '../utils';
import Loading from '../components/loading';
import cloneDeep from 'lodash/cloneDeep';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from "@material-ui/core/styles";
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { Redirect } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete';

const surveyDefaults = {
    title: '',
    categories: [],
    tags: [],
    locales: [
        {
            locale: 'en',
            title: '',
            pre_text: '',
            post_text: '',
        },
    ],
    question_count: '',
};

const styles = theme => ({
    blurb: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    webhookDetails: {
        display: 'block',
    },
});

class SurveyAccordian extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTitle: props.survey.title,
            survey: props.survey,
            updated: false,
        };
    }

    updateState(opts) {
        const survey = Object.assign(this.state.survey, opts);

        this.setState({
            updated: true,
            survey: survey,
        });
    }

    async updateSurvey(e) {
        e.preventDefault();

        this.setState(
            {
                loading: true,
            },
            async function() {
                const survey = this.state.survey;

                const payload = {
                    locales: survey.locales,
                    categories: survey.categories,
                    tags: survey.tags,
                    metadata: survey.metadata,
                    question_count: survey.question_count,
                    question_ids: survey.question_ids,
                    title: survey.title,
                };

                const nullIfEmpty = [
                    "question_count",
                ];

                nullIfEmpty.forEach(function (field) {
                    if (payload[field] === "") {
                        payload[field] = null;
                    }
                });

                var response;

                if (survey.survey_uuid) {
                    response = await this.props.putSecure(`/survey/${survey.survey_uuid}`, payload);

                } else {
                    response = await this.props.postSecure('/survey/', payload);
                }

                const data = await response.json();

                if (response.ok) {
                    this.setState(
                        {
                            loading: false,
                            updated: false,
                            survey: data,
                        },
                        function () {
                            this.props.onUpdate(data);
                        }
                    );

                } else {
                    const data = await response.json();
                    console.log(data);

                    this.setState({
                        error: data.name,
                        loading: false,
                    });
                }
            }
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={"survey-header-"+this.state.survey_id}
                >
                    <Typography className={classes.heading}>{this.state.showTitle}</Typography>
                    <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.surveyDetails}>
                    {this.state.error && (<Alert severity="error">{this.state.error}</Alert>)}
                    <form onSubmit={(e) => this.updateSurvey(e)}>
                        <SurveyForm
                            survey={this.state.survey}
                            accountCategories={this.props.accountCategories}
                            accountTags={this.props.accountTags}
                            onChange={(state) => this.updateState(state)}
                        />
                        <div className="flex-group">
                            <div>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!this.state.updated || this.state.loading}
                                type="submit"
                            >
                                Update
                            </Button>
                            </div>
                            <div>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.props.embedCallback}
                            >
                                Embed
                            </Button>
                            </div>
                            <div>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.props.deleteCallback}
                            >
                                Delete
                            </Button>
                            </div>
                        </div>
                        <List>
                            <ListItemText primary={"Last submission at " + friendlyDateTime(this.state.survey.last_submission_at)}/>
                        </List>
                        <List>
                            <ListItemText primary={"Last completed at " + friendlyDateTime(this.state.survey.last_completion_at)}/>
                        </List>
                        <List>
                            <ListItemText primary={"Created at " + friendlyDateTime(this.state.survey.created_at)}/>
                        </List>
                        <List>
                            <ListItemText primary={"Updated at " + friendlyDateTime(this.state.survey.updated_at)}/>
                        </List>
                    </form>
                </AccordionDetails>
            </Accordion>
        );
    }
}

class SurveyLocale extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            pre_text: this.props.pre_text,
            post_text: this.props.post_text,
            locale: this.props.locale
        };
    }

    shouldComponentUpdate(nextProps, nextState) { 
        return (
            this.state.title !== nextState.title ||
            this.state.pre_text !== nextState.pre_text ||
            this.state.post_text !== nextState.post_text ||
            this.state.locale !== nextState.locale
        );
    }

    handleOnChange(state) {
        this.setState(
            state,
            function() {
                if (this.props.onChange) {
                    this.props.onChange(this.state);

                } else {
                    console.warn('probably want a onChange handler here');
                }
            }
        );
    }

    render() {
        return (
            <div className="survey-locale">
                <div className="flex-group">
                    <FormControl>
                        <TextField
                            required
                            id={'title-' + this.state.locale}
                            label="Title"
                            value={this.state.title}
                            onChange={(e) => this.handleOnChange({ title: e.target.value })}
                        />
                        <FormHelperText>A title for the survey, which will be shown to the user</FormHelperText>
                    </FormControl>
                </div>
                <div className="flex-group">
                    <FormControl>
                        <TextField
                            label="Header"
                            id={'pre-text-' + this.state.locale}
                            value={this.state.pre_text}
                            onChange={(e) => this.handleOnChange({ pre_text: e.target.value })}
                        />
                        <FormHelperText>This text can be used to preface all questions.</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <TextField
                            label="Footer"
                            id={'post-text-' + this.state.locale}
                            value={this.state.post_text}
                            onChange={(e) => this.handleOnChange({ post_text: e.target.value })}
                        />
                        <FormHelperText>This is text that you'd like to appear after questions.</FormHelperText>
                    </FormControl>
                </div>
            </div>
        );
    }
}

class SurveyCreateDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            survey: cloneDeep(surveyDefaults),
        };
    }

    handleClose() {
        this.props.onClose();
    }

    async createSurvey(e) {
        e.preventDefault();

        this.setState(
            {
                loading: true,
            },
            async function() {
                const survey = this.state.survey;

                const payload = {
                    locales: survey.locales,
                    categories: survey.categories,
                    tags: survey.tags,
                    metadata: survey.metadata,
                    question_count: survey.question_count,
                    question_ids: survey.question_ids,
                    title: survey.title,
                };

                const nullIfEmpty = [
                    "question_count",
                ];

                nullIfEmpty.forEach(function (field) {
                    if (payload[field] === "") {
                        payload[field] = null;
                    }
                });

                var response;

                if (survey.survey_uuid) {
                    response = await this.props.putSecure(`/survey/${survey.survey_uuid}`, payload);

                } else {
                    response = await this.props.postSecure('/survey/', payload);
                }

                const data = await response.json();

                if (response.ok) {
                    this.setState(
                        {
                            loading: false,
                            survey: cloneDeep(surveyDefaults),
                        },
                        function () {
                            this.props.onCreate(data);
                        }
                    );

                } else {
                    const data = await response.json();
                    console.log(data);

                    this.setState({
                        error: data.name,
                        loading: false,
                    });
                }
            }
        );
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to={this.state.redirect} />
            )
        }

        const { classes } = this.props;

        return (
            <Fragment>
                <Dialog onClose={() => this.handleClose()} aria-labelledby="create-survey-title" open={this.props.open}>
                    <DialogTitle id="edit-survey-title">Create survey</DialogTitle>
                    <DialogContent>
                        {this.state.error && (<Alert severity="error">{this.state.error}</Alert>)}
                        <form onSubmit={(e) => this.createSurvey(e)}>
                            <SurveyForm
                                accountCategories={this.props.accountCategories}
                                accountTags={this.props.accountTags}
                                onChange={(state) => this.setState({ survey: state })}
                                survey={surveyDefaults}
                            />
                            <Typography className={classes.blurb}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={this.state.loading}
                                >
                                    Create survey
                                </Button>
                            </Typography>
                        </form>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

class SurveyEmbedDialog extends Component {
    handleClose() {
        this.props.onClose();
    }

    render() {
        return (
            <Dialog onClose={() => this.handleClose()} aria-labelledby="embed-survey-title" open={this.props.open}>
                <DialogTitle id="embed-survey-title">Embed survey</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This survey's unique ID is below and should be used
                        when referencing this survey with any embed widgets,
                    or the Onva API.
                    </DialogContentText>
                    <DialogContentText>
                        <code>{this.props.survey_uuid}</code>
                    </DialogContentText>
                    <DialogContentText>
                        For more information on how to use this identifier to embed the survey in a web page, or for other examples on how to engage your target audience, visit the <Link to={"/embed/" + this.props.survey_uuid}>Embed section</Link>.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}


class SurveyForm extends Component {
    constructor(props) {
        super(props);
        
        var tags = [];
        const survey = props.survey;

        if (survey.tags) {
            tags = survey.tags.map(function (tag) { return tag.tag });
        }
        
        var categories = [];

        if (survey.categories) {
            categories = survey.categories.map(function (category) { return category.category });
        }

        this.state = {
            survey_uuid: survey.survey_uuid || "",
            title: survey.title || "",
            question_ids: survey.question_ids || [],
            question_count: survey.question_count || "",
            locales: cloneDeep(survey.locales) || [],
            metadata: cloneDeep(survey.metadata) || {},
            tags: tags,
            categories: categories,
        };
    }

    updateState(opts) {
        this.setState(
            opts,
            function() {
                if (this.props.onChange) {
                    this.props.onChange(this.state);

                } else {
                    console.warn('probably want an onChange handler here');
                }
            }
        );
    }

    updateLocale(locale, state) {
        var updated = false;
        var locales = this.state.locales;

        for (var i = 0; i < locales.length; i++) {
            if (locales[i].locale === locale) {
                locales[i] = state;
                updated = true;
                break;
            }
        }

        if (!updated) {
            locales.push(state);
        }

        this.updateState({
            locales: locales
        });
    }

    render() {
        const me = this;
        
        return (
            <div className="survey-form">
                <div className="flex-group">
                    <FormControl>
                        <TextField required id="title" label="Title" value={this.state.title} onChange={(e) => this.updateState({ title: e.target.value })} />
                        <FormHelperText>Internal title for this survey</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <TextField
                            id="question_count"
                            label="Number of questions to ask"
                            min="1"
                            type="number"
                            value={this.state.question_count}
                            onChange={(e) => this.updateState({ question_count: e.target.value || null })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormHelperText>Specify the total number of questions a user should be asked, regardless of the number of questions available in the survey. Leave this empty to ask all questions available to the survey.</FormHelperText>
                    </FormControl>
                </div>
                <h3>
                    Content
                </h3>
                <p>
                    The title of the survey will usually be the first thing that the user sees. The pre-text will appear before the questions, and the post-text will appear after the questions.
                </p>
                <div className="survey-locales">
                {
                    this.state.locales.map(function (locale) {
                        return (
                            <SurveyLocale
                                key={"survey-" + me.state.survey_uuid + "-" + locale}
                                label={locale.locale}
                                locale={locale.locale}
                                title={locale.title}
                                pre_text={locale.pre_text}
                                post_text={locale.post_text}
                                onChange={(state) => me.updateLocale(locale.locale, state)}
                            />
                        );
                    })
                }
                </div>
                <h3>
                    Question filtering
                </h3>
                <p>
                    The categories and tags chosen here will reflect categories and tags that have already been created along with the questions that you created for the survey.
                </p>
                <div className="flex-group">
                    <FormControl>
                        <Autocomplete
                            id="categories"
                            multiple
                            freeSolo
                            onChange={(e, obj) => this.updateState({ categories: obj })}
                            value={this.state.categories}
                            options={this.props.accountCategories || []}
                            loading={this.props.accountCategories === null}
                            renderInput={(params) => (
                                <TextField {...params} label="Categories" margin="normal" variant="standard" />
                            )}
                        />
                        <FormHelperText>Which categories of question should be included?</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <Autocomplete
                            id="tags"
                            multiple
                            freeSolo
                            value={this.state.tags}
                            options={this.props.accountTags || []}
                            loading={this.props.accountTags === null}
                            onChange={(e, obj) => this.updateState({ tags: obj })}
                            renderInput={(params) => (
                                <TextField {...params} label="Tags" margin="normal" variant="standard" />
                            )}
                        />
                        <FormHelperText>Which question tags should be included?</FormHelperText>
                    </FormControl>
                </div>
            </div>
        );
    }
}


class Surveys extends Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            showEmbedFor: null,
            surveys: null,
            alertOpenFor: null,
            showCreate: false,
        };
    }

    async componentDidMount() {
        this.setState(
            {
                loading: true,
            },
            async function() {
                let response = await this.props.getSecure("/survey/");
                let data = await response.json();
                const surveys = data.surveys;

                response = await this.props.getSecure("/categories/");
                data = await response.json();
                const cats = data.categories.map(function (cat) { return cat.category; });

                response = await this.props.getSecure("/tags/");
                data = await response.json();
                const tags = data.tags.map(function (tag) { return tag.tag; });

                this.setState({
                    loading: false,
                    accountCategories: cats,
                    accountTags: tags,
                    surveys: surveys,
                });
            }
        );
    }

    showSurveyEmbed(survey_uuid) {
        this.setState({
            showEmbedFor: survey_uuid,
        });
    }

    addCreatedSurvey(survey) {
        this.setState({
            surveys: this.state.surveys.concat([ survey ]),
            showCreate: false,
            alertOpenFor: "created",
        })
    }

    async deleteSurvey(survey_uuid) {
        if (window.confirm('Are you sure?')) {
            this.setState(
                {
                    loading: true,
                },
                async function() {
                    const response = await this.props.deleteSecure(`/survey/${survey_uuid}`);

                    if (response.ok) {
                        const surveys = this.state.surveys.filter(function (survey) { return survey.survey_uuid !== survey_uuid });

                        this.setState({
                            surveys: surveys,
                            alertOpenFor: "delete",
                            loading: false,
                        });

                    } else {
                        this.setState({
                            loading: false,
                        });

                        window.alert('Something went wrong, please try again!');
                        console.log(await response.json());
                    }
                }
            );
        }
    }

    render() {
        const { classes } = this.props;
        const me = this;

        const alertCopy = {
            "updated": "Survey updated",
            "created": "Survey created",
            "delete": "Survey deleted",
        };

        return (
            <div className="surveys">
                <Loading open={this.state.loading}/>
                <SurveyEmbedDialog
                    open={this.state.showEmbedFor !== null}
                    survey_uuid={this.state.showEmbedFor}
                    onClose={() => this.setState({ showEmbedFor: null })}
                />
                <SurveyCreateDialog
                    open={this.state.showCreate}
                    accountTags={this.state.accountTags}
                    accountCategories={this.state.accountCategories}
                    onClose={() => this.setState({ showCreate: false })}
                    onCreate={(survey) => this.addCreatedSurvey(survey)}
                />
                <Snackbar open={Boolean(this.state.alertOpenFor)} autoHideDuration={3000} onClose={() => this.setState({ alertOpenFor: null })}>
                    <MuiAlert severity="success" elevation={6} variant="filled">
                        {alertCopy[this.state.alertOpenFor]}
                    </MuiAlert>
                </Snackbar>
                <h1>Surveys</h1>
                <p>
                    Traditionally a survey would be a defined list of 20 questions that the user is expected to answer all of, and then you tally up the results. The better way to think of surveys in the Onva platform is a set of rules to determine which questions from the full set should be considered for the user, and how they should be prioritised.
                </p>
                <Typography className={classes.blurb}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({ showCreate: true })}
                    >Create survey</Button>
                </Typography>
                {
                    this.state.surveys === null ? 
                        (<Loading open={true}/>) :
                        this.state.surveys.length === 0 ?
                        (<Typography>No surveys defined - yet!</Typography>) :
                        (this.state.surveys.map(function (survey) {
                            return (
                                <SurveyAccordian
                                    key={"survey-" + survey.survey_uuid}
                                    accountTags={me.state.accountTags}
                                    accountCategories={me.state.accountCategories}
                                    survey={survey}
                                    onUpdate={() => me.setState({ updated: true })}
                                    embedCallback={() => me.showSurveyEmbed(survey.survey_uuid)}
                                    deleteCallback={() => me.deleteSurvey(survey.survey_uuid)}
                                />
                            );
                        }))
                }
            </div>
        );
    }
}

SurveyCreateDialog = withUtils(withStyles(styles)(SurveyCreateDialog));
SurveyAccordian = withUtils(withStyles(styles)(SurveyAccordian));

export default withDashboardLayout(withUtils(withStyles(styles)(Surveys)));
