import withDashboardLayout from '../components/layout';
import { Component } from 'react';
import { withUtils } from '../utils';
import { NotFound } from '../views';
import QuestionForm from '../components/question-form';
import cloneDeep from 'lodash/cloneDeep';
import { Redirect } from 'react-router-dom'
import Loading from '../components/loading';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';

const answerDefaults = {
    answer_id: "",
    value: "",
    active: true,
    more_detail: false,
    locales: [
        {
            locale: "en",
            content: "",
        },
    ],
    metadata: {},
};

const questionDefaults = {
    question_id: '',
    active: true,
    priority: 10,
    minimum_answers: 1,
    maximum_answers: 1,
    max_responses_per_user: "",
    targeting: {},
    metadata: {},
    locales: [
        {
            locale: 'en',
            content: '',
            pre_text: '',
            post_text: ''
        },
    ],
    tags: [],
    categories: [],
    dependencies: [],
    answers: [
        answerDefaults,
    ],
};

function editableAnswer(data) {
    const canEdit = [
        'active',
        'locales',
        'metadata',
        'answer_id',
        'more_detail',
        'value',
    ];

    var answer = {};

    canEdit.forEach(function (field) {
        answer[field] = data[field];
    });

    return answer;
}

function editableQuestion(data) {
    const canEdit = [
        'active',
        'dependencies',
        'locales',
        'max_responses_per_user',
        'minimum_answers',
        'maximum_answers',
        'metadata',
        'priority',
        'question_id',
        'targeting',
    ];

    var question = {};

    canEdit.forEach(function (field) {
        question[field] = data[field];
    })

    question["answers"] = data.answers.map(editableAnswer);
    question["categories"] = data.categories.map(function(category) { return category.category });
    question["tags"] = data.tags.map(function(tag) { return tag.tag });

    return question;
}

class QuestionEdit extends Component {
    constructor(props) {
        super(props);

        const questionId = props.match.params.questionId || null;
        let question = null;

        if (questionId === null) {
            question = questionDefaults;
        }

        this.state = {
            question: question,
            questionId: questionId,
            accountTags: null,
            accountCategories: null,
            simple: true,
            loading: false,
            error: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState) { 
        return (
            this.state.accountCategories !== nextState.accountCategories ||
            this.state.accountTags !== nextState.accountTags ||
            this.state.question !== nextState.question ||
            this.state.error !== nextState.error ||
            this.state.loading !== nextState.loading ||
            this.state.simple !== nextState.simple ||
            this.state.redirect !== nextState.redirect
        );
    }

    async componentDidMount() {
        this.setState(
            {
                loading: true,
            },
            async function() {
                let newState = {
                    loading: false,
                };

                if (this.state.questionId !== null) {

                    let response = await this.props.getSecure(`/question/${this.state.questionId}`);

                    if (response.status === 404) {
                        newState['question'] = false;

                    } else {
                        const data = await response.json();

                        var question = editableQuestion(data);
 
                        newState['question'] = question;
                    }

                    /*
                        }).catch(function (error) {
                            if (parseInt(error.message) === 404) {
                                me.setState({
                                    question: false,
                                });
                            } else {
                                window.alert(error);
                            }
                        });
                        */
                }

                let response = await this.props.getSecure("/categories/");
                let data = await response.json();
                const cats = data.categories.map(function (cat) { return cat.category; });

                response = await this.props.getSecure("/tags/");
                data = await response.json();
                const tags = data.tags.map(function (tag) { return tag.tag; });

                newState["accountCategories"] = cats;
                newState["accountTags"] = tags;

                this.setState(newState);
            }
        );
    }

    async handleSubmit(e) {
        e.preventDefault();

        this.setState(
            {
                loading: true,
            },
            async function() {
                var url = '/question/';

                if (this.state.questionId !== null) {
                    url += this.state.questionId;
                }

                var payload = cloneDeep(this.state.question);

                const nullIfEmpty = [
                    "max_responses_per_user",
                    "maximum_answers",
                ];

                nullIfEmpty.forEach(function (field) {
                    if (payload[field] === "") {
                        payload[field] = null;
                    }
                });

                /* FIXME this is a hack right now - ordering should not be needed, but it is */
                payload.answers.forEach(function (answer, index) {
                    answer.ordering = index + 1;
                });

                var response;

                if (this.state.questionId) {
                    response = await this.props.putSecure(url, payload);

                } else {
                    response = await this.props.postSecure(url, payload);
                }

                const data = await response.json();

                if (response.ok) {
                    // success, redirect
                    if (this.state.questionId) {
                        this.setState({
                            redirect: '/questions/#updated',
                            loading: false,
                        });
                    } else {
                        this.setState({
                            redirect: '/questions/#created',
                            loading: false,
                        });
                    }
                } else {
                    this.setState({
                        error: true,
                        loading: false,
                    });

                    console.warn(response.status);

                    console.warn(JSON.stringify(data, null, 4));
                }
            }
        );
    }

    updateState(question) {
        this.setState({
            question: question,
        });
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to={this.state.redirect} />
            )
        }

        const creating = (this.state.questionId === null);

        if (!creating) {
            if (this.state.question === false) {
                return <NotFound/>
            }
        }

        /*
        TODO
        - drag and drop for answers

        question id - text
        active - bool
        priority - int
        pre text
        content
        post text
        min/max answers
        max responses per user
        tags
        categories - dynamic
        dependencies (TODO)
        metadata
        targeting

        answers
            answer id
            value
            active
            more detail
            content
            metadata
        */

        return (
            <div>
                <Loading open={this.state.loading}/>
                {this.state.error && (<Alert severity="error">An error occurred</Alert>)}
                {this.state.question !== null && this.state.accountTags !== null && this.state.accountCategories !== null && (
                <div>
                    <div className="flex-group">
                        <h1>{this.state.questionId ? 'Update Question' : 'Create Question'}</h1>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!this.state.simple}
                                        onChange={(e) => this.setState({ simple: !e.target.checked })}
                                        name="advanced"
                                        color="primary"
                                    />
                                }
                                label="Advanced editor"
                            />
                            <FormHelperText>Edit the finer details of your question</FormHelperText>
                        </FormControl>
                    </div>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <QuestionForm
                            simple={this.state.simple}
                            question={this.state.question}
                            answerDefaults={answerDefaults}
                            accountTags={this.state.accountTags}
                            accountCategories={this.state.accountCategories}
                            onChange={(question) => this.updateState(question)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Save question
                        </Button>
                    </form>
                </div>
                )}
            </div>
        );
    }
}

export default withDashboardLayout(withUtils(QuestionEdit));
