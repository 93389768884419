import { Component } from 'react';

class NotFound extends Component {
    render () {
        return (
            <p>Not found</p>
        );
    }
}

export default NotFound;
