import { Component } from 'react';
import OnvaAppBar from './appbar';
import OnvaDrawer from './drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        minWidth: 0 // So the Typography noWrap works
    },
    //toolbar: theme.mixins.toolbar
});

export default function withDashboardLayout(OldComponent) {
    class NewComponent extends Component {
        constructor() {
            super();

            this.state = {
                drawerOpen: true,
            };
        }

        render() {
            const { classes } = this.props;

            return (
                <>
                    <OnvaAppBar
                        toggleDrawer={() => this.setState({ drawerOpen: !this.state.drawerOpen })}
                    />
                    <OnvaDrawer open={this.state.drawerOpen}/>
                    <main className={classes.content}>
                        <Toolbar />
                        <OldComponent />
                    </main>
                    <footer>
                    </footer>
                </>
            )
        }
    }

    return withAuth0(withAuthenticationRequired(withStyles(styles)(NewComponent)));
}
