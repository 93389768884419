import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import App from './app';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import TagManager from 'react-gtm-module'

if (process.env.REACT_APP_GTM_ID) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID
    };

    TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <App />
            </ThemeProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
