import withDashboardLayout from '../components/layout';
import { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

class Home extends Component {
    render() {
        return (
            <Fragment>
                <h1>Welcome!</h1>

                <p>
                    Thanks for trying out the Onva platform. It's still early days and we are certainly missing some features, and some videos on how to do the most important things.
                </p>
                <p>
                    If you'd like a guided tour from one of the team please feel free to reach out to us by emailing <a href="mailto:hello@onva.io">hello@onva.io</a> and we'll be happy to talk you through how to get the most out of the platform.
                </p>

                <h2>Guide</h2>

                <h3>Create questions</h3>
                <p>
                    The first thing to do is to head on over to the <Link to="/questions">questions section</Link> to setup some questions. The Quick Start section can set some questions up for you, but you likely want to create some of your own.
                </p>

                <h3>Define surveys</h3>
                <p>
                    A survey is a set of rules that dictate how questions are chosen, and when they are delivered. Give your survey a name, and it'll help to organise them later. This is done on the <Link to="/surveys/">surveys page</Link>.
                </p>

                <h3>Integrate</h3>
                <p>
                    Finally you should embed the survey you've created in your website, or your app. We have some pre-built widgets in the <Link to='/embed/'>Embed section</Link> which will help you get going.
                </p>
        
                <h3>Other</h3>
                <p>
                    For a whistle stop tour of what we have here:
                </p>
                <ul>
                    <li>Quick Start - some pre-built surveys (at least 1) to get you going quickly</li>
                    <li>Questions - where you view, create, and edit questions that have been used in surveys</li>
                    <li>Surveys - where you manage your surveys</li>
                    <li>Embed - details on how to embed your surveys with our pre-built web widgets (for now)</li>
                    <li>API Keys - if you want to plug into the API</li>
                    <li>Webhooks - if you'd like real-time notifications of survey completion</li>
                    <li>Help - for when you get lost</li>
                </ul>
            </Fragment>
        );
    }

        /*
                <p>Blah</p>

                <h2>What's missing</h2>
                <p>Bit of a brain dump here</p>

                <h3>Basics</h3>
                <ul>
                    <li>
                        Logout
                    </li>
                    <li>
                        Profile handling
                    </li>
                    <li>
                        Profile dropdown does nothing
                    </li>
                    <li>
                        Default list of tags and categories
                    </li>
                    <li>
                        Default setup of questions and surveys (?)
                    </li>
                    <li>
                        Support for multiple locales while editing
                    </li>
                </ul>
                
                <h3>Question and surveys</h3>
                <ul>
                    <li>
                        It's currently unclear how these two connect, how questions
                        are included in surveys and how they differ from a
                        traditional approach
                    </li>
                    <li>
                        You can't delete questions or surveys, or categories and tags for that matter
                    </li>
                    <li>
                        Do we need a page for category and tag handling?
                    </li>
                </ul>

                <h3>Help and support</h3>
                <ul>
                    <li>
                        A knowledgebase would go a long way to helping people understand some basic stuff.
                    </li>
                </ul>

                <h3>Integration help</h3>
                <ul>
                    <li>
                        So you've got an account, you've created questions and surveys, but now what?
                    </li>
                    <li>
                        Public docs, or a clear marker that the docs are available and not all are secure endpoints
                    </li>
                </ul>

                <h3>Reporting</h3>
                <ul>
                    <li>
                        There's no reporting yet beyond "this got asked" and "this was answered.
                    </li>
                    <li>
                        Basic charting for a given question of which answer was provided, including toggles for answer IDs or answer values
                    </li>
                </ul>
            */
}

export default withDashboardLayout(Home);
