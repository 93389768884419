import { Component } from 'react';
import { withUtils } from '../utils';
import Button from '@material-ui/core/Button';
import Loading from '../components/loading';

class NPSSurvey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            survey: props.survey,
            loading: false,
        };

        this.create = this.create.bind(this);
    }

    async create() {
        // https://help.retently.com/en/articles/474652-nps-question-translated-in-33-languages
        // for when the nps goes into multiple languages
        // TODO would be nice to ask the company name
        this.setState(
            {
                loading: true,
            },
            async function () {
                const question = {
                    question_id: 'nps-question',
                    multiple_choice: false,
                    active: true,
                    priority: 1,
                    locales: [
                        {
                            locale: "en",
                            content: "How likely are you to recommend us to a friend?",
                            pre_text: "",
                            post_text: ""
                        }
                    ],
                    metadata: {},
                    targeting: {},
                    minimum_answers: 1,
                    maximum_answers: 1,
                    max_responses_per_user: 1,
                    tags: [
                    ],
                    categories: [
                        "nps"
                    ],
                    dependencies: [],
                    answers: [
                        {
                            answer_id: 'nps-answer-0',
                            value: "0",
                            ordering: 0,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "0"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-1',
                            value: "1",
                            ordering: 1,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "1"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-2',
                            value: "2",
                            ordering: 2,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "2"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-3',
                            value: "3",
                            ordering: 3,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "3"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-4',
                            value: "4",
                            ordering: 4,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "4"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-5',
                            value: "5",
                            ordering: 5,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "5"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-6',
                            value: "6",
                            ordering: 6,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "6"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-7',
                            value: "7",
                            ordering: 7,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "7"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-8',
                            value: "8",
                            ordering: 8,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "8"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-9',
                            value: "9",
                            ordering: 9,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "9"
                                },
                            ],
                        },
                        {
                            answer_id: 'nps-answer-10',
                            value: "10",
                            ordering: 10,
                            active: true,
                            more_detail: false,
                            metadata: {},
                            locales: [
                                {
                                    locale: "en",
                                    content: "10"
                                },
                            ],
                        },
                    ]
                };

                const survey = {
                    title: "NPS Survey",
                    categories: [
                        "nps"
                    ],
                    tags: [
                    ],
                    question_ids: [],
                    metadata: {},
                    question_count: 1,
                    locales: [
                        {
                            locale: "en",
                            title: "NPS Survey",
                            pre_text: "",
                            post_text: ""
                        }
                    ]
                };

                // create question
                var response = await this.props.postSecure("/question/", question);

                this.setState({
                    loading: false,
                });

                if (response.ok) {
                    // handle errors
                    response = await this.props.postSecure('/survey/', survey);

                    if (response.ok) {
                        const me = this;
                        const survey = await response.json();

                        this.setState(
                            {
                                survey: survey,
                            },
                            function() {
                                me.props.onCreate(survey);
                            },
                        );
                    } else {
                        window.alert('An error occurred');
                    }
                } else {
                    window.alert('An error occurred');
                }
            }
        );
    }

    render() {
        var survey = this.state.survey || this.props.survey;

        if (!survey) {
            return (
                <Button variant="contained" color="primary" onClick={this.create}>Create NPS Survey</Button>
            );
        }

        return (
            <div>
                <Loading open={this.state.loading}/>
                <p>
                    Copy the following code into the end of the &lt;head&gt; section of your website to have the NPS widget display to all new visitors.
                </p>
                <code>
                    &lt;script src="https://widgets.onva.io/onva-nps.js" data-survey-uuid="{survey.survey_uuid}"&gt;&lt;/script&gt;
                </code>
            </div>
        );
    }
}

export default withUtils(NPSSurvey);
